// Commom Plugins
(function($) {

	'use strict';

	// Scroll to Top Button.
	if (typeof theme.PluginScrollToTop !== 'undefined') {
		theme.PluginScrollToTop.initialize();
	}

	// Tooltips
	if ($.isFunction($.fn['tooltip'])) {
		$('[data-tooltip]:not(.manual), [data-plugin-tooltip]:not(.manual)').tooltip();
	}

	// Popover
	if ($.isFunction($.fn['popover'])) {
		$(function() {
			$('[data-plugin-popover]:not(.manual)').each(function() {
				var $this = $(this),
					opts;

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.popover(opts);
			});
		});
	}

	// Validations
	if (typeof theme.PluginValidation !== 'undefined') {
		theme.PluginValidation.initialize();
	}

	// Match Height
	if ($.isFunction($.fn['matchHeight'])) {

		$('.match-height').matchHeight();

		// Featured Boxes
		$('.featured-boxes .featured-box').matchHeight();

		// Featured Box Full
		$('.featured-box-full').matchHeight();

	}

}).apply(this, [jQuery]);

// Animate
(function($) {

    'use strict';

    if ($.isFunction($.fn['themePluginAnimate'])) {

        $(function() {
            $('[data-plugin-animate], [data-appear-animation]').each(function() {
                var $this = $(this),
                    opts;

                var pluginOptions = $this.data('plugin-options');
                if (pluginOptions)
                    opts = pluginOptions;

                $this.themePluginAnimate(opts);
            });
        });

    }

}).apply(this, [jQuery]);

//Toggle
(function($) {

	'use strict';

	if ($.isFunction($.fn['themePluginToggle'])) {

		$(function() {
			$('[data-plugin-toggle]:not(.manual)').each(function() {
				var $this = $(this),
					opts;

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginToggle(opts);
			});
		});

	}

}).apply(this, [jQuery]);

(function($) {

	'use strict';

	//// Sticky Header
	//if (typeof theme.StickyHeader !== 'undefined') {
	//	theme.StickyHeader.initialize();
	//}

	// Nav Menu
	if (typeof theme.Nav !== 'undefined') {
		theme.Nav.initialize();
	}

	//// Search
	//if (typeof theme.Search !== 'undefined') {
	//	theme.Search.initialize();
	//}
    //
	//// Newsletter
	//if (typeof theme.Newsletter !== 'undefined') {
	//	theme.Newsletter.initialize();
	//}
    //
	//// Account
	//if (typeof theme.Account !== 'undefined') {
	//	theme.Account.initialize();
	//}

}).apply(this, [jQuery]);